@import "../../style/_mixins";
@import "../../style/_variable";
@import "../../style/_colors";


.iframe {
  width: 100%;
  height: calc(100vh - 100px);
  top: 0;
}

.wrapper {
  @include wrapper-96;
  padding-top: 10px;
  padding-bottom: 20px;
  margin: 121px auto 0;
  transition: all 0.5s linear;
  display: block;
  justify-content: center;
  align-items: center;

  .main {

    user-select: none;
    -moz-user-select: none;

    .block {
      width: 100%;

      .blockNum {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
          font-weight: 700;
          font-size: 240px;
          line-height: 297px;
        }

        img {
          pointer-events: none;
          object-fit: cover;
          z-index: -1;
          position: absolute;
          width: 800px;
          height: 670px;
        }

        h1:nth-child(1) {
          margin-right: 370px;
        }
      }

      .blockBtn {
        margin-top: 80px;
        display: grid;
        justify-items: center;

        p {
          text-align: center;
          @include main-h1-744px;
          color: $text-color-opacity-7;

        }

        .navBtn {
          @include button;
          width: 294px;
          height: 57px;
          margin-top: 41px;
          padding: 16px 24px;
          gap: 10px;
          border-radius: 12px;
        }

        .navBtn:hover {
          @include button-hover;
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .wrapper {
    @include wrapper-40;

    .main {
      .block {
        .blockNum {
          img {
            height: 610px;
          }
        }

        .blockBtn {
          margin-top: 64px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    min-height: 600px;
  }
}

@media (max-width: 743px) {
  .wrapper {
    @include wrapper-16;
    margin: 96px auto 96px;
    min-height: 560px;
    .main {
      .block {
        .blockBtn {
          p {
            @include main-h1-2-375px;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .wrapper {
    margin-top: 70px;
    min-height: 530px;
    .main {
      .block {
        .blockNum {
          h1 {
            font-weight: 700;
            font-size: 200px;
            line-height: 257px;
          }

          img {
            width: 700px;
            height: 570px;
          }

          h1:nth-child(1) {
            margin-right: 310px;
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .wrapper {
    min-height: 470px;
    .main {
      .block {
        .blockNum {
          h1 {
            font-weight: 700;
            font-size: 160px;
            line-height: 217px;
          }

          img {
            width: 600px;
            height: 480px;
          }

          h1:nth-child(1) {
            margin-right: 280px;
          }
        }

        .blockBtn {
          p {
            @include mini-h1-1024px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .wrapper {
    margin: 72px auto 64px;
    min-height: 350px;
    .main {
      .block {
        .blockNum {
          h1 {
            font-size: 126.725px;
            line-height: 157px;
          }

          img {
            width: 500px;
            height: 400px;
          }

          h1:nth-child(1) {
            margin-right: 195px;
          }
        }

        .blockBtn {
          p {
            @include p-800px;
          }

          .navBtn {
            margin-top: 16px;
            width: 242px;
            height: 41px;
            font-size: 14px;
            padding: 10px 24px;
          }
        }
      }
    }
  }
}
