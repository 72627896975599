@import "../../../style/_mixins";

.case_item {
  @include case-item;
  background-color: $cases-background-blue;
}

.case_item:hover .case_item_content {
  opacity: 1;
  cursor: pointer;
}

.macbook_pro {
  position: absolute;
  width: 900px;
  height: 342px;
  top: 45px;
  left: -250px;
  transition: all 0.5s;
}

.case_item:hover .macbook_pro {
  width: 600px;
  height: 228px;
  left: -80px;
  top: 80px;
}

@media screen and (max-width: 1439px) {
  .case_item {
    height: 305px;

    .case_item_content {
      cursor: pointer;
      padding: 28px 30px 28px 24px;

      .title {
        width: 80%;
        h3 {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 8px;
        }
      }

      img {
        margin-bottom: 6px;
      }
    }
  }

  .macbook_pro {
    width: 750px;
    height: 342px;
    left: -210px;
  }

  .case_item:hover .macbook_pro {
    width: 550px;
    height: 228px;
    left: -85px;
    top: 53px;
  }
}

@media screen and (max-width: 1024px) {
  .case_item {
    .case_item_content {
      opacity: 1;

      .title {
        width: 69%;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .macbook_pro {
    top: 15px;
    left: -205px;
  }

  .case_item:hover .macbook_pro {
    width: 600px;
    height: 228px;
    left: -100px;
    top: 40px;
  }
}

@media screen and (max-width: 743px) {
  .macbook_pro {
    width: 700px;
    height: 266px;
    left: unset;
    right: -180px;
    top: 30px;
  }

  .case_item:hover .macbook_pro {
    width: 700px;
    height: 266px;
    left: unset;
    right: -180px;
    top: 30px;
  }
}
