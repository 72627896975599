@import "../../style/_colors";

.carousel_card {
  height: 100%;
  padding: 40px;
  background: linear-gradient(130deg, $dark-grey-card 45%, rgba(49,32,62,1) 75%, rgba(68,26,100,1) 100%);
  border-radius: 16px;
  position: relative;

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .header_left {
      display: flex;
      align-items: center;

      img {
        height: 112px;
        width: 112px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 36px;
      }

      h3 {
        font-size: 32px;
        line-height: 39px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
        font-weight: 300;
      }
    }

    .header_right {
      p {
        padding: 8px 16px 6px;
        background: $feedback-form-date;
        border-radius: 8px;
        color: $text-color;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
      }
    }
  }

  .quote_mob {
    position: absolute;
    left: 14px;
    top: 178px;
  }

  .review_text_mob {
    font-size: 24px;
    line-height: 29px;
    font-weight: 300;
  }
}

@media screen and (max-width: 864px) {
  .carousel_card {
    &_header {
      .header_left {
        img {
          width: 88px;
          height: 88px;
          margin-right: 16px;
        }

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .quote_mob {
      top: 154px;
    }

    .review_text_mob {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@media screen and (max-width: 599px) {
  .carousel_card {
    padding: 24px;

    &_header {
      margin-bottom: 24px;

      .header_left {
        img {
          width: 72px;
          height: 72px;
        }

        h3 {
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .header_right {
        p {
          font-size: 8px;
          line-height: 10px;
          padding: 4px 12px;
        }
      }
    }

    .quote_mob {
      left: 0;
      top: 105px;
    }

    .review_text_mob {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
