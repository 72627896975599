@import "../../style/_colors";

.request {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .requestHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 614px;
    margin-right: 22px;

    .h1 {
      font-size: 48px;
      font-weight: 600;
      line-height: 59px;
      margin-bottom: 24px;
    }

    .titleFree {
      display: inline;
      background: $ellipse-color-background;
      color: $primary-color;
      border-radius: 16px;
      padding: 8px 12px;
    }

    p {
      width: 80%;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      color: $text-color-opacity-7;
    }

    .desktopRequestBottom {
      display: flex;
    }
  }

  .mobileRequestBottom {
    display: none;
  }
}

@media screen and (max-width: 1439px) {
  .request {
    flex-direction: column;

    .requestHeader {
      max-width: 944px;
      margin-right: 0;

      .h1 {
        margin-bottom: 32px;
      }

      p {
        width: 79%;
        margin-bottom: 24px;
      }

      .desktopRequestBottom {
        display: none;
      }
    }

    .mobileRequestBottom {
      display: flex;
    }
  }
}

@media screen and (max-width: 1023px) {
  .request {
    .requestHeader {
      .h1 {
        width: 100%;
        font-size: 32px;
        line-height: 39px;
      }

      p {
        width: 95%;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

@media screen and (max-width: 743px) {
  .request {
    .requestHeader {
      .h1 {
        width: 100%;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 8px;
      }

      p {
        width: 100%;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
      }
    }
  }
}
