@import "../../style/_colors";

.cases_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  .add_project {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dark-grey-card;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.5s;

    .block {
      position: relative;
      width: 200px;
      height: 200px;
      background: $feedback-form-date;
      border-radius: 14px;
      transition: all 0.3s;

      .vector {
        width: 0;
        position: absolute;
        height: 126px;
        left: 92px;
        top: 37px;
        background: $dark-grey-card;
        border: 8px solid $dark-grey-card;
        border-radius: 8px;
        transition: all 0.3s;
      }

      .vector:nth-child(2) {
        transform: rotate(-90deg);
      }
    }

    h2 {
      display: none;
    }
  }

  .add_project:hover .block {
    width: 230px;
    height: 230px;

    .vector {
      height: 146px;
      top: 42px;
      left: 105px;
      background: $text-color;
      border: 10px solid $text-color;
    }
  }

  .our_project {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h2 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .cases_grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .cases_grid {
    grid-template-columns: repeat(2, 1fr);

    .add_project {
      flex-direction: column;
      justify-content: flex-end;

      .block {
        width: 126px;
        height: 126px;

        .vector {
          height: 85px;
          border: 5px solid $dark-grey-card;;
          left: 58px;
          top: 25px;
        }
      }

      h2 {
        display: block;
        padding: 29px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }
    }

    .add_project:hover .block {
      width: 140px;
      height: 140px;

      .vector {
        height: 90px;
        top: 25px;
        left: 64px;
        border: 6px solid $text-color;
      }
    }

    .our_project {
      display: none;
    }
  }
}

@media screen and (max-width: 743px) {
  .cases_grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);

    .add_project {
      flex-direction: column-reverse;

      .block {
        width: 110px;
        height: 110px;

        .vector {
          height: 70px;
          border: 4px solid $dark-grey-card;;
          left: 50px;
          top: 20px;
        }
      }

      h2 {
        padding: 34px 0;
      }
    }
  }
}

