@import "../../style/_colors.scss";

footer {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;

  .footerContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    padding: 64px 96px;

    .footerLeft {
      display: flex;
      flex-direction: column;
      .logo {
        display: flex;
        align-items: flex-end;
        color: $text-color;
        text-decoration: none;
      }

      .logo > img {
        height: 46px;
      }

      .footerInfo,
      .footerPrivacyPolicy {
        font-size: 16px;
        line-height: 19px;
        font-weight: 300;
        color: $text-color-opacity-7;
        margin-top: 20px;
      }

      .footerInfo:nth-child(3) {
        margin-top: 8px;
      }

      a {
        font-size: 16px;
        line-height: 19px;
        font-weight: 300;
        color: $text-color-opacity-7;
        margin-top: 104px;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .footerRight {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .footerPhone,
      .footerEmail {
        margin-top: 18px;
        font-size: 40px;
        font-weight: 500;
        line-height: 59px;
        a{
          text-decoration: none;
          color: $text-color
        }
      }


      .footerEmail {
        margin: 24px 0 40px;
      }
    }
  }

  .backgroundCircle {
    position: absolute;
    left: 20%;
    right: 20%;
    top: 120%;
    width: 60%;
    height: 130px;
    background: $primary-color-opacity-7;
    filter: blur(150px);
  }
}

@media screen and (max-width: 1439px) {
  footer {
    .footerContent {
      padding: 64px 40px;
    }

    .backgroundCircle {
      top: 120%;
      right: 0;
      left: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  footer {
    .footerContent {
      flex-direction: column;

      .footerLeft {
        .footerPrivacyPolicy {
          margin-top: 24px;
        }

        a {
          margin-top: 24px;
        }
      }

      .footerRight {
        align-items: flex-start;

        .footerPhone {
          margin-top: 64px;
        }
      }
    }

    .backgroundCircle {
      top: 110%;
    }
  }
}

@media screen and (max-width: 743px) {
  footer {
    .footerContent {
      padding: 64px 16px;
    }

    .footerContent {
      .footerLeft {
        .logo > img {
          width: 25px;
          height: 25px;
        }
      }

      .footerRight {
        .footerPhone,
        .footerEmail {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }

    .backgroundCircle {
      top: 100%;
    }
  }
}
