.links {
  display: flex;

  a > img {
    width: 48px;
    height: 48px;
  }

  a {
    margin-right: 25px;
  }

  a:nth-last-child(1) {
    margin: 0;
  }
}

@media screen and (max-width: 699px) {
  .links {
    a > img {
      width: 32px;
    }
  }
}
