@import "../../../style/_mixins";

.case_item {
  @include case-item;
  grid-area: 1 / 1 / 2 / 3;
  background: $cases-background-pink;
}

.case_item:hover .case_item_content {
  cursor: pointer;
  opacity: 1;
}

.iphone_12_pro {
  position: absolute;
  width: 900px;
  height: 675px;
  bottom: -210px;
  right: -130px;
  transition: all 0.5s;
}

.case_item:hover .iphone_12_pro {
  bottom: -100px;
  width: 730px;
  height: 548px;
  right: 0;
}

@media screen and (max-width: 1439px) {
  .case_item {
    height: 305px;

    .case_item_content {
      cursor: pointer;
      padding: 28px 30px 28px 24px;

      .title {
        width: 80%;
        h3 {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 8px;
        }
      }

      img {
        margin-bottom: 6px;
      }
    }
  }

  .iphone_12_pro {
    width: 800px;
    height: 600px;
    top: -85px;
    bottom: unset;
  }

  .case_item:hover .iphone_12_pro {
    width: 700px;
    height: 525px;
    right: -135px;
    top: -105px;
    bottom: unset;
  }
}

@media screen and (max-width: 1024px) {
  .case_item {
    .case_item_content {
      opacity: 1;

      .title {
        width: 69%;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .case_item {
    grid-area: unset;
  }

  .iphone_12_pro {
    width: 600px;
    height: 450px;
    right: -230px;
    top: -65px;
  }

  .case_item:hover .iphone_12_pro {
    width: 570px;
    height: 427px;
    right: -175px;
    top: -65px;
  }
}

@media screen and (max-width: 743px) {
  .iphone_12_pro {
    width: 565px;
    height: 424px;
    bottom: -55px;
    right: -200px;
  }

  .case_item:hover .iphone_12_pro {
    width: 565px;
    height: 424px;
    bottom: -55px;
    right: -200px;
  }
}
