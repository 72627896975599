@import "../../../style/_mixins";

.case_item {
  @include case-item;
  background-color: $cases-background-red;
}

.case_item:hover .case_item_content {
  opacity: 1;
  cursor: pointer;
}

.iphone_macbook {
  position: absolute;
  width: 840px;
  height: 480px;
  right: -150px;
  top: 50px;
  transition: all 0.5s;
}

.case_item:hover .iphone_macbook {
  width: 570px;
  height: 326px;
  right: -50px;
  top: 35px;
}

@media screen and (max-width: 1439px) {
  .case_item {
    height: 305px;

    .case_item_content {
      cursor: pointer;
      padding: 28px 30px 28px 24px;

      .title {
        width: 80%;
        h3 {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 8px;
        }
      }

      img {
        margin-bottom: 6px;
      }
    }
  }

  .iphone_macbook {
    width: 640px;
    height: 366px;
    top: 25px;
    left: -150px;
  }

  .case_item:hover .iphone_macbook {
    width: 570px;
    height: 326px;
    top: 20px;
    left: -150px;
  }
}

@media screen and (max-width: 1024px) {
  .case_item {
    .case_item_content {
      opacity: 1;

      .title {
        width: 69%;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .iphone_macbook {
    width: 620px;
    height: 355px;
    top: 20px;
    left: -170px;
  }

  .case_item:hover .iphone_macbook {
    width: 620px;
    height: 355px;
    top: 15px;
    left: -190px;
  }
}

@media screen and (max-width: 743px) {
  .iphone_macbook {
    width: 600px;
    height: 343px;
    top: 10px;
    right: -95px;
    left: unset;
  }

  .case_item:hover .iphone_macbook {
    width: 600px;
    height: 343px;
    top: 10px;
    right: -95px;
    left: unset;
  }
}
