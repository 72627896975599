@import "../../style/_colors";
@import "../../style/_mixins";

.feedback_car_mobile {
  .title {
    @include title-h1-1440px;
    margin-bottom: 56px;
  }

  .feedback {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &_carousel_container {
      display: flex;
      min-width: 100%;
      transition: all 0.5s ease-in-out;
      width: calc(1100%);
      height: 100%;

      .cases_carousel_item {
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  .cases_carousel_slide_counter {
    display: flex;
    font-size: 24px;
    align-items: center;
    margin-top: 40px;

    .prev_slide,
    .next_slide {
      cursor: pointer;
      width: 13px;
      height: 26px;
      margin-right: 23px;
    }

    .next_slide {
      margin-left: 23px;
    }

    span {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
    }

    .cases_carousel_current_slide_mob {
      color: $primary-color;
      min-width: 30px;
      display: inline-block;
    }
  }
}

@media screen and (max-width: 1023px) {
  .feedback_car_mobile {
    .title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 743px) {
  .feedback_car_mobile {
    .section_header {
      &_name {
        font-size: 33px;
      }
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    .cases_carousel_slide_counter {
      margin-top: 16px;

      img {
        height: 20px;
      }

      .prev_slide,
      .next_slide {
        height: 20px;
        margin-right: 17px;
      }

      .next_slide {
        margin-left: 17px;
      }

      span {
        font-size: 14px;
        line-height: 17px;
      }

      .cases_carousel_current_slide_mob {
        color: $primary-color;
        min-width: unset;
        display: inline-block;
      }
    }
  }
}
