@import "./_colors";

@mixin button {
  padding: 16px 24px;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  font-family: "Gilroy", sans-serif;
  cursor: pointer;
  color: $text-color;
  background: linear-gradient(90deg, $hover-button-blue-color 10%, $primary-color 50%, $hover-button-pink-color 90%);
  transition: background-size 1s;
  background-size: 600%;
  background-position: center;
}

@mixin button-hover {
  transition: background-size 1s;
  background-size: 100%;
}

@mixin ellipse {
  position: absolute;
  width: 350px;
  height: 350px;
  background: $primary-color;
  filter: blur(150px);
  border-radius: 50%;
  z-index: -1;
}

@mixin shadow-image {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 100%);

  img {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

@mixin block-with-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color-opacity-2;
  border-radius: 8px;
  padding: 10px;
}

@mixin case-item {
  overflow: hidden;
  border-radius: 16px;
  height: 360px;
  position: relative;
  transition: all 0.5s ease-in-out;
  user-select: none;

  .case_item_content {
    position: absolute;
    opacity: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 32px;
    background: linear-gradient(180deg, rgba(22, 22, 22, 0.28) 28%, $feedback-form-date 100%);
    border-radius: 16px;
    transition: all 0.5s ease-in-out;

    .title {
      width: 75%;

      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 49px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $text-color-opacity-8;
      }
    }

    img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
}

@mixin wrapper-96 {
  max-width: 1440px;
  padding: 112px 96px 0;
  position: relative;
  min-height: 100vh;
}

@mixin wrapper-40 {
  padding: 97px 40px 0;
}

@mixin wrapper-16 {
  padding: 73px 16px 0;
}

@mixin wrapper-case-96 {
  max-width: 1440px;
  padding: 0 96px;
  margin: 0 auto;
}

@mixin wrapper-case-40 {
  padding: 0 40px;
}

@mixin wrapper-case-16 {
  padding: 0 16px;
}

@mixin h1-1440px {
  font-weight: 700;
  font-size: 96px;
  line-height: 119px;
  color: $text-color-opacity-9
}

@mixin title-h1-1440px {
  font-size: 56px;
  line-height: 69px;
  font-weight: 700;
  margin-bottom: 40px;
}

@mixin default-h1-1440px {
  font-size: 56px;
  line-height: 69px;
  font-weight: 700;
}

@mixin main-h1-744px {
  font-size: 40px;
  line-height: 50px;
}

@mixin main-h1-2-744px {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

@mixin main-h1-1024px {
  font-size: 64px;
  line-height: 79px;
}

@mixin main-h1-800px {
  font-size: 48px;
  line-height: 59px;
}

@mixin main-h1-375px {
  font-size: 24px;
  line-height: 30px;
}

@mixin main-h1-1-375px {
  font-size: 24px;
  line-height: 29px;
}

@mixin h2-1440px {
  font-size: 32px;
  line-height: 38px;
}

@mixin main-h1-2-375px {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

@mixin main-h1-3-375px {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

@mixin main-h1-4-375px {
  font-size: 17px;
  line-height: 20px;
}

@mixin h1-5-375px {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin mini-h1-1024px {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

@mixin p-1440px {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

@mixin p-cases-1440px {
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
}

@mixin p-cases-2-1440px {
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
}

@mixin p-cases-3-1440px {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
}

@mixin p-cases-1024px {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}

@mixin p2-1440px {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}

@mixin p3-1440px {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin p-800px {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

@mixin p2-800px {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

@mixin p-cases-800px {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}
@mixin p-cases-375px {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin p-375px {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

@mixin p2-375px {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

@mixin span-1440px {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: rgba(255, 255, 255, 0.9);
}
