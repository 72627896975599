$ellipse-color-background: #2f1244;
$primary-color: #9300FC;
$primary-color-opacity-2: rgba(147, 0, 252, 0.2);
$primary-color-opacity-3: rgba(147, 0, 252, 0.3);
$primary-color-opacity-7: rgba(147, 0, 252, 0.7);
$text-color: #FFFFFF;
$text-color-opacity-5: rgba(255, 255, 255, 0.5);
$text-color-opacity-6: rgba(255, 255, 255, 0.6);
$text-color-opacity-7: rgba(255, 255, 255, 0.7);
$text-color-opacity-8: rgba(255, 255, 255, 0.8);
$text-color-opacity-9: rgba(255, 255, 255, 0.9);
$text-black-color: #000;
$feedback-form-date: #161616;
$feedback-form-date-opacity-2: rgba(22, 22, 22, 0.2);
$feedback-form-date-opacity-3: rgba(22, 22, 22, 0.3);
$feedback-form-date-opacity-5: rgba(22, 22, 22, 0.5);
$feedback-form-date-opacity-8: rgba(22, 22, 22, 0.8);
$feedback-form-date-opacity-9: rgba(22, 22, 22, 0.9);
$dark-grey-card: #242424;
$form-color-background: #4a4a4a;
$form-color-placeholder: rgba(18, 18, 18, 0.4);
$hover-button-pink-color: #C520FF;
$hover-button-blue-color: #6D61FF;
$hover-icon-color: #63119D;
$cases-background-pink: #C06ECC;
$cases-background-purple: #B234FF;
$cases-background-yellow: #FFE381;
$cases-background-red: #FE6161;
$cases-background-blue: #5051F9;
$background-red-opacity-2: rgba(255, 92, 92, 0.2);
$background-pink: #F7EDFF;
