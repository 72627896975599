@import "../../../style/_mixins";

.case_item {
  @include case-item;
  background-color: $cases-background-yellow;
}

.case_item:hover .case_item_content {
  opacity: 1;
  cursor: pointer;
}

.iphone_13_pro {
  position: absolute;
  width: 250px;
  height: 402px;
  top: 15px;
  left: 70px;
  transition: all 0.5s;
}

.case_item:hover .iphone_13_pro {
  width: 350px;
  height: 563px;
  left: 15px;
  top: 25px;
}

@media screen and (max-width: 1439px) {
  .case_item {
    height: 305px;

    .case_item_content {
      cursor: pointer;
      padding: 28px 30px 28px 24px;

      .title {
        width: 80%;
        h3 {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 8px;
        }
      }

      img {
        margin-bottom: 6px;
      }
    }
  }

  .iphone_13_pro {
    width: 285px;
    height: 402px;
    left: 9px;
    top: 40px;
  }

  .case_item:hover .iphone_13_pro {
    width: 225px;
    height: 362px;
    top: 30px;
    left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .case_item {
    .case_item_content {
      opacity: 1;

      .title {
        width: 69%;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .iphone_13_pro {
    left: 30px;
  }
}

@media screen and (max-width: 743px) {
  .iphone_13_pro {
    left: unset;
    right: 10px;
    width: 315px;
    height: 506px;
    top: 10px;
  }

  .case_item:hover .iphone_13_pro {
    left: unset;
    right: 10px;
    width: 315px;
    height: 506px;
    top: 10px;
  }
}
