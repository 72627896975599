@import "../../../style/colors";

.mediaBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .socialMedia {
    display: flex;
    align-items: center;
    margin-top: 32px;

    img {
      margin-right: 32px;
      width: 48px;
      height: 40px;
    }

    .whatsapp {
      width: 48px;
      height: 48px;
    }

    a {
      font-size: 48px;
      font-weight: 600;
      line-height: 59px;
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
      margin: 0;
      text-decoration: none;
      transition: all 0.5s;
    }
  }
}

@media screen and (max-width: 1439px) {
  .mediaBlock {
    flex-direction: row;

    .socialMedia {
      margin: 120px 48px 0 0;

      img {
        margin-right: 16px;
      }

      a {
        font-size: 48px;
        font-weight: 600;
        line-height: 59px;
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
        margin: 0;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .mediaBlock {
    .socialMedia {
      margin: 96px 48px 0 0;

      img {
        width: 40px;
        height: 33px;
      }

      .whatsapp {
        width: 40px;
        height: 40px;
      }

      a {
        font-size: 32px;
        line-height: 39px;
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}

@media screen and (max-width: 743px) {
  .mediaBlock {
    flex-direction: column;
    .socialMedia {
      margin: 24px 0 0;

      img {
        width: 24px;
        height: 20px;
      }

      .whatsapp {
        width: 24px;
        height: 24px;
      }

      a {
        font-size: 24px;
        line-height: 29px;
        border-bottom: 1px solid $primary-color;
      }
    }
  }
}
