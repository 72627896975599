@import "../../../style/_mixins";

.case_item {
  @include case-item;
  background-color: $cases-background-purple;
}

.case_item:hover .case_item_content {
  opacity: 1;
  cursor: pointer;
}

.display {
  position: absolute;
  width: 630px;
  height: 424px;
  top: 35px;
  left: -210px;
  transition: all 0.5s;
}

.case_item:hover .display {
  width: 500px;
  height: 336px;
  left: -50px;
  top: 50px;
}

@media screen and (max-width: 1439px) {
  .case_item {
    height: 305px;

    .case_item_content {
      cursor: pointer;
      padding: 28px 30px 28px 24px;

      .title {
        width: 80%;
        h3 {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 8px;
        }
      }

      img {
        margin-bottom: 6px;
      }
    }
  }

  .display {
    width: 520px;
    height: 350px;
    top: 30px;
    left: -120px;
  }

  .case_item:hover .display {
    height: 336px;
    left: -10px;
    top: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .case_item {
    .case_item_content {
      opacity: 1;

      .title {
        width: 69%;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .display {
    left: unset;
    width: 500px;
    height: 337px;
    right: -160px;
    top: 20px;
  }

  .case_item:hover .display {
    left: unset;
    width: 500px;
    height: 337px;
    right: -135px;
    top: 15px;
  }
}

@media screen and (max-width: 743px) {
  .display {
    left: unset;
    width: 550px;
    height: 370px;
    right: -145px;
    top: 35px;
  }

  .case_item:hover .display {
    left: unset;
    width: 550px;
    height: 370px;
    right: -145px;
    top: 35px;
  }
}
