@import "../../../style/_colors";
@import "../../../style/_mixins";

.requestForm {
  display: flex;
  flex-direction: column;
  min-width: 612px;
  width: 612px;

  .formLine {
    display: flex;
    flex-direction: row;

    .formField {
      border: none;
      padding: 16px 24px;
      height: 51px;
      background: $form-color-background;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      color: $text-color;
      margin: 8px 0;
      width: 100%;
      font-family: "Gilroy", sans-serif;
    }

    .error {
      border: 2px solid #FF5C5C;
      background: #4f4a4a;
      box-shadow: inset 0px -7px 22px -15px #ff5c5c;
    }

    .error::placeholder {
      color: $text-color;
    }
  }

  .formLine input:nth-child(1) {
    margin-right: 8px;
  }

  .formLine input:nth-child(2) {
    margin-left: 8px;
  }

  .formField {
    border: none;
    padding: 16px 24px;
    height: 51px;
    background: $form-color-background;
    border-radius: 8px;
    font-size: 16px;
    line-height: 19px;
    color: $text-color;
    margin: 8px 0;
    width: 100%;
    font-family: "Gilroy", sans-serif;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $text-color;
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 1000px $form-color-background inset;
    color-scheme: dark;
  }

  .formField:focus-visible {
    outline: none;
    border: 2px solid $primary-color;
  }

  .error {
    border: 2px solid #FF5C5C;
    background: #4f4a4a;
    box-shadow: inset 0px -7px 22px -15px #ff5c5c;
  }

  .error::placeholder {
    color: $text-color;
  }

  .textarea {
    resize: none;
    max-width: 100%;
    min-height: 152px;
    font-family: "Gilroy", sans-serif;
  }

  .tagsBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;

    p {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
    }

    .tagsList {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 8px;

      input[type="checkbox"]{
        width: 0;
        height: 0;
        &:checked + label{
          background: $primary-color;
          border-color: $primary-color;
        }
      }

      .tag {
        display: flex;
        user-select: none;
        cursor: pointer;
        line-height: 19px;
        font-weight: 300;
        font-size: 16px;
        height: fit-content;

        &_label{
          border: 1px solid $text-color;
          border-radius: 100px;
          padding: 8px 18px;
          cursor: pointer;
        }
      }
    }
  }

  .fileBlock {
    margin: 40px 0;

    .file {
      display: flex;
      flex-wrap: wrap;
      &_name {
        display: flex;
        padding: 8px;
        width: 204px;
        height: 40px;
        background: #242424;
        border-radius: 8px;
        align-items: center;
        justify-content: space-between;
        margin-right: 8px;
        margin-bottom: 10px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          cursor: pointer;
          width: 15px;
        }
      }
    }

    .attachFile {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
    }

    .attachFile > p {
      font-size: 24px;
      margin: 0 0 0 20px;
      font-weight: 300;
      color: $text-color;
    }

    .attachFile > img {
      width: 28px;
      height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      color: $text-color-opacity-5;
      margin-top: 7px;
    }
  }


  input[type="file"] {
    display: none;
  }

  .sendRequest {
    @include button;
    height: 57px;
    margin-top: -48px;

  }

  .sendRequest:hover {
    @include button-hover;
  }

  .politics {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $text-color-opacity-7;
    margin-top: 8px;
    width: 555px;
    font-family: 'Inter', sans-serif;
  }

  .politics > a {
    color: $text-color;
  }
}

@media screen and (max-width: 1023px) {
  .requestForm {
    width: 100%;
  }
}

@media screen and (max-width: 743px) {
  .requestForm {
    min-width: unset;

    .formLine {
      flex-direction: column;

      input {
        margin: 8px 0 !important;
      }
    }

    .attachFile {
      margin: 0 0 25px;
    }

    .sendRequest {
      height: 48px;
      font-size: 16px;
      line-height: 20px;
    }

    .politics {
      width: 100%;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
