$size-154px: 154px;
$size-144px: 144px;
$size-132px: 132px;
$size-120px: 120px;
$size-110px: 110px;
$size-104px: 104px;
$size-100px: 100px;
$size-96px: 96px;
$size-80px: 80px;
$size-64px: 64px;
$size-56px: 56px;
$size-40px: 40px;
$size-36px: 36px;
$size-32px: 32px;
$size-24px: 24px;
$size-16px: 16px;
