* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

#callibri-module-area{
	color: black;
}

/* Track */
::-webkit-scrollbar-track {
	background: #161616;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #7b7b7b;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #4a4a4a;
}

@font-face {
	font-family: 'Gilroy';
	src: url('https://gists.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy-Semibold.woff');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('https://gists.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy-Regular.woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('https://gists.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy-Medium.woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('https://gists.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy-Bold.woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('https://gists.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy-Light.woff');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

body {
	background-color: #161616;
	color: #fff;
	font-family: "Gilroy", serif, sans-serif;
	cursor: default;
	margin: 0 auto;
	overflow-x: hidden;
}

.tns-nav,
.tns-visually-hidden {
	display: none;
}

#app {
	min-height: 100vh;
}

footer {
	opacity: 0;
}

#main-container + footer {
	opacity: 1;
}

@media screen and (max-width: 1440px) {
	html {
		overflow-x: hidden;
	}
}
