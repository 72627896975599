@import "../../style/_mixins";
@import "../../style/_variable";
@import "../../style/_colors";

.wrapper {
  @include wrapper-96;
  margin: 121px auto 0;
  transition: all 0.5s linear;

  .success {
    display: grid;
    position: relative;

    h1 {
      max-width: 1022px;
      min-height: 238px;
      @include h1-1440px;
    }

    p {
      margin-top: 24px;
      max-width: 510px;
      min-height: 98px;
      font-weight: 600;
      @include main-h1-744px;
    }

    span {
      margin-top: 40px;
      max-width: 575px;
      min-height: 96px;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
    }

    .background1 {
      @include ellipse;
      left: 72%;
      top: 3em;
      -webkit-backdrop-filter: blur(0px);
    }

    .background2 {
      background-image: url("/assets/components/success/Shape.webp");
      background-repeat: no-repeat;
      width: 533px;
      background-size: 533px;
      height: 533px;
      position: absolute;
      top: 18em;
      left: 77%;
      z-index: -1;
    }

    .background3 {
      background-image: url("/assets/components/success/Shape-1.webp");
      background-repeat: no-repeat;
      width: 435px;
      background-size: 435px;
      height: 435px;
      position: absolute;
      top: 12em;
      left: 46%;
      z-index: -1;
    }

    .background4 {
      background-image: url("/assets/components/success/Shape-2.webp");
      background-repeat: no-repeat;
      width: 176px;
      background-size: 176px;
      height: 176px;
      position: absolute;
      top: -5em;
      left: 65%;
      z-index: -1;
    }

    .links {
      margin-top: 32px;
    }
  }

  .cases {
    margin-top: $size-144px;

    h1 {
      @include default-h1-1440px;
      margin-bottom: $size-40px;
    }
  }

  .feedback {
    margin-top: $size-144px;
    margin-bottom: $size-144px;
  }

}

@media (max-width: 1440px) {
  .wrapper {
    @include wrapper-40;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .success {

      h1 {
        max-width: 695px;
        min-height: 158px;
        @include main-h1-1024px;
      }

      span {
        margin-top: 64px;
      }

      .background1 {
        left: 72%;
        top: 3em;
      }

      .background2 {
        width: 422px;
        background-size: 422px;
        height: 422px;
        top: 18em;
        left: 77%;
      }

      .background3 {
        width: 332px;
        background-size: 332px;
        height: 332px;
        top: 8.5em;
        left: 49.5%;
      }

      .background4 {
        top: -5em;
        left: 65%;
      }
    }

    .cases {
      margin-top: $size-120px;
    }
    .feedback {
      margin-top: $size-120px;
      margin-bottom: $size-120px;
    }
  }
}

@media (max-width: 744px) {
  .wrapper {
    @include wrapper-16;

    .success {
      display: grid;
      position: relative;

      h1 {
        max-width: 695px;
        min-height: 138px;
        @include default-h1-1440px ;
      }

      p {
        @include main-h1-3-375px;
      }

      span {
        margin-top: 32px;
        min-height: 76px;
        font-size: 32px;
        line-height: 38px;
      }

      .background1 {
        left: 70%;
      }

      .background2 {
        width: 292px;
        background-size: 292px;
        height: 292px;
        top: 16em;
        left: 75%;
      }

      .background3 {
        width: 210px;
        background-size: 210px;
        height: 210px;
        top: 6em;
        left: 51%;
      }

      .background4 {
        width: 136px;
        background-size: 136px;
        height: 136px;
        top: -2em;
        left: 75%;
      }
    }

    .cases {
      margin-top: $size-96px;

      h1 {
        @include main-h1-744px ;
      }
    }
    .feedback {
      margin-top: $size-96px;
      margin-bottom: $size-96px;
    }
  }
}

@media (max-width: 500px) {
  .wrapper {
    margin-top: 68px;

    .success {
      display: grid;
      position: relative;

      h1 {
        max-width: 487px;
        min-height: 90px;
        font-size: 36px;
        line-height: 45px;
      }

      p {
        margin-top: 10px;
        max-width: 405px;
        min-height: 60px;
        @include mini-h1-1024px;
      }

      span {
        margin-top: 24px;
        max-width: 441px;
        min-height: 76px;
        @include mini-h1-1024px;
        font-weight: 400;
      }

      .background1 {
        left: 70%;
      }

      .background2 {
        width: 240px;
        background-size: 240px;
        height: 240px;
        top: 16em;
        left: 70%;
      }

      .background3 {
        width: 160px;
        background-size: 160px;
        height: 160px;
        top: 8em;
        left: 74%;
      }

      .background4 {
        width: 102px;
        background-size: 102px;
        height: 102px;
        top: -2em;
        left: 68%;
      }

      .links {
        margin-top: 0;
      }
    }

    .cases {
      h1 {
        @include main-h1-375px;
        margin-bottom: $size-24px;
      }
    }
  }
}

@media (max-width: 420px) {
  .wrapper {
    .success {

      h1 {
        max-width: 325px;
        min-height: 60px;
        @include main-h1-2-744px;
      }

      p {
        margin-top: 8px;
        max-width: 270px;
        min-height: 40px;
        @include h1-5-375px
      }

      span {
        margin-top: 24px;
        max-width: 294px;
        min-height: 38px;
        @include p-800px
      }

      .background1 {
        left: 55%;
        top: -3em;
      }

      .background2 {
        width: 164px;
        background-size: 164px;
        height: 164px;
        top: 15em;
        left: 41%;
      }

      .background3 {
        width: 133px;
        background-size: 133px;
        height: 133px;
        top: 4em;
        left: 70%;
      }

      .background4 {
        width: 68px;
        background-size: 68px;
        height: 68px;
        top: -1em;
        left: 62%;
      }

      .links {
        margin-top: 8px;
      }
    }

    .cases {
      margin-top: $size-64px;
    }
    .feedback {
      margin-top: $size-64px;
      margin-bottom: $size-64px;
    }
  }
}
